import store from './store'
let socket = null

function send (params) {
  return new Promise((resolve, reject) => {
    socket.send(JSON.stringify(params))
    socket.onmessage = (e) => {
      const data = JSON.parse(e.data)
      console.log(data)
      if (data.result === 'true') {
        resolve(data)
      } else {
        reject(data)
      }
    }
  })
}

const csp = {
  connectTumSocket: () => {
    return new Promise((resolve, reject) => {
      // socket = new WebSocket('wss://localhost:6127/tumarcsp/') // server side
      socket = new WebSocket('ws://localhost:6126/tumarcsp/') // local
      socket.onopen = () => {
        store.commit('updateSocket', true)
        resolve()
      }
      socket.onclose = () => {
        store.commit('updateSocket', false)
        reject(new Error('disconnected'))
      }
      socket.onerror = (error) => {
        store.commit('updateSocket', false)
        reject(error)
        socket.close()
      }
    })
  },
  setAPIKey: (options) => {
    const param = {
      TumarCSP: 'APIKey',
      Function: options
    }
    return send(param)
  },
  getReaders: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'getReaders',
      Param: options
    }
    return send(param)
  },
  getDevices: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'getDevices',
      Param: options
    }
    return send(param)
  },
  getDevicesForSoftware:(options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'getDevices',
      Param: options
    }
    return send(param)
  },
  getObjects: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'getObjects',
      Param: options
    }
    return send(param)
  },
  CheckPassword: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'CheckPassword',
      Param: options
    }
    return send(param)
  },
  GetSerialNumber: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'GetSerialNumber',
      Param: options
    }
    return send(param)
  },
  changePassword: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'changePassword',
      Param: options
    }
    return send(param)
  },
  CreatePKCS10: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'CreatePKCS10',
      Param: options
    }
    return send(param)
  },
  getPKCS10Info: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'getPKCS10Info',
      Param: options
    }
    return send(param)
  },
  DeleteKeyPair: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'DeleteKeyPair',
      Param: options
    }
    return send(param)
  },
  GenerateKeyPair: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'GenerateKeyPair',
      Param: options
    }
    return send(param)
  },
  GetFreeMemorySize: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'GetFreeMemorySize',
      Param: options
    }
    return send(param)
  },
  SetCertificate: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'SetCertificate',
      Param: options
    }
    return send(param)
  },
  createPkcs7: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'createPkcs7',
      Param: options
    }
    return send(param)
  },
  verifyPkcs7: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'verifyPkcs7',
      Param: options
    }
    return send(param)
  },
  getCertificateInfo: (options) => {
    console.log(options)
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'getCertificateInfo',
      Param: options
    }
    return send(param)
  },
  Str2Hex: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'Str2Hex',
      Param: options
    }
    return send(param)
  },
  GenerateObjectName: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'GenerateObjectName',
      Param: options
    }
    return send(param)
  },
  revokeCertificate: (options) => {
    const param = {
      TumarCSP: 'HardAPI',
      Function: 'revokeCertificate',
      Param: options
    }
    return send(param)
  }
}

Plugin.install = function (Vue, options) {
  Vue.cryptoSocket = csp
  window.cryptoSocket = csp
  Object.defineProperties(Vue.prototype, {
    cryptoSocket: {
      get () {
        return csp
      }
    },
    $cryptoSocket: {
      get () {
        return csp
      }
    }
  })
}

export default Plugin
