export default {
  methods: {
    startSocket () {
      this.cryptoSocket.connectTumSocket().then(() => {
          // localhost
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          //
          // 172.16.172.201
          this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')

          // ca.gamma.kz
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')

          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          // this.cryptoSocket.setAPIKey('lIpLQq8yVeLQnN6RNV9owtJODBd1VTzyJJpZlvCGWs5WwqrRqtKJl1WE9B7Ls/M1Kh2iqDRc3xBYd1Npf7DMPUgYVwYh8tZifLuWLpaul+FT1MyrFux74ymuq7+EBo+fg8zZe/Vd8jtLCeLwgEk5qKU6HSqQNrSJzXCQ7omdd02hX/p36SG75IICosmSJzJt+Nzm8fU6T8+UYqPPmqJGRuEBJT7vO6eroAnBkSmRmXrzuQdQHR2r7CxWAa+5BWkCjTAL5matKMZX6GN/pLGjFOUH2vhKz5BwiWlwNohVdzEd3du07o+Ob00Ed4u98xnX1FP1chJ7Cdyge0WOXpzg0g==')
          .then(() => {
            this.$store.commit('updateSocketKey', true)
          }).catch(error => this.$store.commit('updateSocketKey', false))
      })
    }
  }
}