import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/pages/Home'),
      meta: {
        title: 'HOME'
      }
    },
    {
      path: '/index.html',
      name: 'Home',
      component: () => import('@/pages/Home'),
      meta: {
        title: 'HOME'
      }
    },
    {
      path: '/index.htm',
      name: 'Home',
      component: () => import('@/pages/Home'),
      meta: {
        title: 'HOME'
      }
    },

    {
      path: '/index',
      name: 'Home',
      component: () => import('@/pages/Home'),
      meta: {
        title: 'HOME'
      }
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('@/pages/Partners'),
      meta: {
        title: 'Recommended security tokens'
      }
    },
    {
      path: '/FAQ',
      name: 'FAQ',
      component: () => import('@/pages/FAQ'),
      meta: {
        title: 'FAQ'
      }
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('@/pages/Support'),
      meta: {
        title: 'SUPPORT'
      }
    },
    {
      path: '/instructions',
      name: 'instructions',
      component: () => import('@/pages/Instructions'),
      meta: {
        title: 'Instructions'
      }
    },
    {
      path: '/software',
      name: 'software',
      component: () => import('@/pages/Software'),
      meta: {
        title: 'Download software'
      }
    },
    {
      path: '/conditions',
      name: 'conditions',
      component: () => import('@/pages/Conditions'),
      meta: {
        title: 'Terms of Use'
      }
    },
    {
      path: '/confedence',
      name: 'confedence',
      component: () => import('@/pages/Confidence'),
      meta: {
        title: 'Privacy Policy'
      }
    },
    {
      path: '/samruk',
      name: 'samruk',
      component: () => import('@/pages/Samruk'),
      meta: {
        title: 'Potential issues with accessing the Samruk-Kazyna e-procurement portal'
      }
    },
    {
      path: '/keyproblem',
      name: 'keyproblem',
      component: () => import('@/pages/KeyProblem'),
      meta: {
        title: 'Security token issues'
      }
    },
    {
      path: '/info',
      name: 'info',
      component: () => import('@/pages/Info'),
      meta: {
        title: 'Technical description of certificate'
      }
    },
    {
      path: '/keycheck',
      name: 'keycheck',
      component: () => import('@/pages/KeyCheck'),
      meta: {
        title: 'Check security token'
      }
    },
    {
      path: '/getrevoke',
      name: 'getrevoke',
      component: () => import('@/pages/GetRevoke'),
      meta: {
        title: 'Revocation of the EDS certificate'
      }
    },
    {
      path: '/revoke',
      name: 'revoke',
      component: () => import('@/pages/Revoke'),
      meta: {
        title: 'Revocation of DS Certificate'
      }
    },
    {
      path: '/revokeresult',
      name: 'revokeresult',
      component: () => import('@/pages/RevokeResult'),
      meta: {
        title: 'Revocation of DS Certificate'
      }
    },
    {
      path: '/order_cert',
      name: 'order_cert',
      component: () => import('@/pages/OrderCert'),
      meta: {
        title: 'Order a digital signature certificate'
      }
    },
    {
      path: '/getcertificate',
      name: 'getcertificate',
      component: () => import('@/steps/GetCertificate'),
      meta: {
        title: 'Order a digital signature certificate'
      }
    },
    {
      path: '/order_status:order?',
      name: 'order_status',
      component: () => import('@/pages/OrderStatus'),
      meta: {
        title: 'Order status'
      }
    },
    {
      path: '/tariff',
      name: 'tariff',
      component: () => import('@/pages/documents/Tariff.vue'),
      meta: {
        title: 'Tariffs'
      }
    },
    {
      path: '/rules',
      name: 'rules',
      component: () => import('@/pages/documents/Rules.vue'),
      meta: {
        title: 'CA Rules'
      }
    },
    {
      path: '/policy',
      name: 'policy',
      component: () => import('@/pages/documents/Policy.vue'),
      meta: {
        title: 'CA Certificate Policies'
      }
    },
    {
      path: '/offer',
      name: 'offer',
      component: () => import('@/pages/documents/Offer.vue'),
      meta: {
        title: 'Public Offer Agreement'
      }
    },
    {
      path: '/checktools',
      name: 'Checktools',
      component: () => import('@/steps/Checktools.vue'),
      meta: {
        title: 'Checking keys and token names'
      }
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/components/404'),
      meta: {
        title: 'page not found'
      }
    }
  ]
})

export default router
