import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/css/app.scss'
import Cryptosocket from '@/cryptosocket'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VeeValidate from 'vee-validate'
import { messages as ruValidation } from 'vee-validate/dist/locale/ru'
import { messages as enValidation } from 'vee-validate/dist/locale/en'
import { messages as kkValidation } from '@/lang/kk'
import ruValidationMess from '@/lang/ruValidationMess.json'
import enValidationMess from '@/lang/enValidationMess.json'
import ruValidationAttr from '@/lang/ruValidationAttr.json'
import enValidationAttr from '@/lang/enValidationAttr.json'
import kkValidationMess from '@/lang/kkValidationMess.json'
import kkValidationAttr from '@/lang/kkValidationAttr.json'
import VueI18n from 'vue-i18n'
import ru from '@/lang/russian.json'
import en from '@/lang/english.json'
import kk from '@/lang/kazakh.json'
import VueNotification from '@kugatsu/vuenotification'
import mixins from './mixins'

Vue.use(VueNotification, { timer: 20  })
Vue.use(require('vue-moment'), { moment })
Vue.use(VueAxios, axios)
Vue.use(Cryptosocket)
Vue.config.productionTip = false
Vue.use(VueI18n)
const moment = require('moment')
lang === 'kk' ? moment.locale('kk') : ('ru' ? moment.locale('ru') : moment.locale('en'))

const lang = localStorage.getItem('locale') || 'ru'

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'ru',
  silentTranslationWarn: true,
  messages: { ru: ru, en: en, kk: kk }
})

Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    ru: { messages: { ...ruValidation, ...ruValidationMess },
      attributes: ruValidationAttr },
    en: { messages: { ...enValidation, ...enValidationMess },
      attributes: enValidationAttr },
    kk: { messages: { ...kkValidation, ...kkValidationMess },
      attributes: kkValidationAttr }
  }
})

window.onbeforeunload = function () {
  localStorage.removeItem('currency')
  localStorage.removeItem('currencyId')
  localStorage.removeItem('template')
  localStorage.removeItem('templateId')
  localStorage.removeItem('price')
};

router.beforeEach((to, from, next) => {
  document.title = i18n.tc(to.meta.title) + ' | ' + i18n.tc('GT banner text')
  next()
})

Vue.mixin(mixins)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
