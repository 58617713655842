<template>

  <nav class="navbar is-primary" role="navigation" aria-label="main navigation">

    <!-- Модальное окно при заказе кк сертификата -->
    <div class="modal is-active" v-if="corporateModalActive">
      <div class="modal-background" v-on:click="openCorporateModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('Connect the security token') }}</p>
          <figure class="image is-48x48">
            <img src="@/assets/img/insert_usb.gif" alt="Image">
          </figure>
        </header>
        <section class="modal-card-body">
          <p class="p_modal">{{ $t('Please, make sure that the USB port of your computer is connected with only one security token') }}. {{ $t('Do not remove it before the end of operation') }}.</p>
          <div style="height: 1em;"></div>
          <p class="p_modal"><b>{{ $t('Enter the details of the PIN-envelope') }}</b></p>
          <div style="height: 0.5em;"></div>
          <div class="field">
            <div class="field">
              <label class="label">{{ $t('Order number') }}*</label>
              <div class="control">
                <input v-model.trim="orderNumberC" name="order" v-validate="'required'" class="input" :class="{'has-error': errors.has('orderNumberC')}"  type="text" placeholder="">
                <p v-if="errors.has('order')" class="alert-error ">{{errors.first('order')}}</p>
              </div>
            </div>
            <div class="field">
              <label class="label">{{ $t('Password') }}*</label>
              <div class="control">
                <input v-model.trim="orderPasswordC" name="password" v-validate="'required|checkPass'" data-vv-validate-on="'change'" class="input" :class="{'has-error': errors.has('orderPasswordC')}"  type="password" placeholder="">
                <p v-if="errors.has('password')" class="alert-error ">{{errors.first('password')}}</p>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <a class="button is-primary" v-on:click="GoToCorporate">{{ $t('Continue') }}</a>
          <a class="button" v-on:click="openCorporateModal">{{ $t('Cancel') }}</a>
        </footer>
      </div>
    </div>

    <div class="navbar-brand">
      <router-link to="/" class="navbar-item"><img src="@/assets/img/logo.png"></router-link>
      <button class="button navbar-burger" onclick="document.getElementById('nav-menu').classList.toggle('is-active');">
        <span></span><span></span><span></span>
      </button>
    </div>

    <div class="navbar-menu" id="nav-menu">
      <div class="navbar-end">

        <router-link to="/" class="navbar-item">{{ $t('HOME') }}</router-link>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">{{ $t('SUPPORT') }}</a>
          <div class="navbar-dropdown">
            <router-link to="/instructions" class="navbar-item">{{ $t('Instructions') }}</router-link>
            <router-link to="/partners" class="navbar-item">{{ $t('Recommended security tokens') }}</router-link>
            <router-link to="/software" class="navbar-item">{{ $t('Download software') }}</router-link>
            <router-link to="/FAQ" class="navbar-item">{{ $t('FAQ') }}</router-link>
            <router-link to="/support" class="navbar-item">{{ $t('Contact support') }}</router-link>
          </div>
        </div>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">{{ $t('DOCUMENTS') }}</a>
          <div class="navbar-dropdown">
            <router-link class="navbar-item" :to="{name: 'rules'}" target="_blank">{{ $t('CA Rules') }}</router-link>
            <router-link class="navbar-item" :to="{name: 'policy'}" target="_blank">{{ $t('CA Certificate Policies') }}</router-link>
            <router-link class="navbar-item" :to="{name: 'offer'}" target="_blank">{{ $t('Public Offer Agreement') }}</router-link>
            <router-link class="navbar-item" :to="{name: 'tariff'}" target="_blank">{{ $t('Tariffs') }}</router-link>
            <router-link to="/conditions" class="navbar-item">{{ $t('Terms of Use') }}</router-link>
            <router-link to="/confedence" class="navbar-item">{{ $t('Privacy Policy') }}</router-link>
          </div>
        </div>
        <a class="navbar-item" v-on:click="openCorporateModal">{{ $t('FOR CORPORATE CUSTOMERS') }}</a>
        <a class="navbar-item" href="http://gamma.kz/">GAMMA.KZ</a>
        <a class="navbar-item" :class="{'locale-link': $i18n.locale === 'kk'}" v-model="$i18n.locale" v-on:click="langKZ">KZ</a>
        <a class="navbar-item" :class="{'locale-link': $i18n.locale === 'ru'}" v-model="$i18n.locale" v-on:click="langRU">RU</a>
        <a class="navbar-item" :class="{'locale-link': $i18n.locale === 'en'}" v-model="$i18n.locale" v-on:click="langEN">EN</a>
      </div>
    </div>

  </nav>

</template>

<script>
export default {
  name: 'NavBar',
  data () {
    return {
      corporateModalActive: false,
      orderNumberC: null,
      orderPasswordC: null
    }
  },
  computed: {
    getLang () {
      return this.$route.query.lang ? this.$route.query.lang : null
    },
    lang () {
      return this.getLang ? this.getLang : this.$i18n.locale
    },
    linkLang () {
      return this.lang === 'ru' ? 'ENG' : 'RUS'
    }
  },
  watch: {
    getLang: {
      handler: function (val) {
        if (val) {
          this.$forceUpdate()
          if (this.getLang === 'en') {
            this.$i18n.locale = 'en'
            window.localStorage.setItem('locale', 'en')
            this.$moment.locale('en')
          } else if (this.getLang === 'kk') {
            this.$i18n.locale = 'kk'
            window.localStorage.setItem('locale', 'kk')
            this.$moment.locale('kk')
          } else {
            this.$i18n.locale = 'ru'
            window.localStorage.setItem('locale', 'ru')
            this.$moment.locale('ru')
          }
        }
      },
      immediate: true
    },
    orderNumberC: {
      handler: function (val) {
        if (val) {
          this.orderNumberC = val.toUpperCase()
        }
      }
    }
  },
  methods: {
    langKZ () {
      this.$i18n.locale = 'kk'
      window.localStorage.setItem('locale', 'kk')
      this.$moment.locale('kk')
      document.title = this.$t(this.$router.currentRoute.meta.title) + ' | ' + this.$t('GT banner text')
    },
    langRU () {
      this.$i18n.locale = 'ru'
      window.localStorage.setItem('locale', 'ru')
      this.$moment.locale('ru')
      document.title = this.$t(this.$router.currentRoute.meta.title) + ' | ' + this.$t('GT banner text')
    },
    langEN () {
      this.$i18n.locale = 'en'
      window.localStorage.setItem('locale', 'en')
      this.$moment.locale('en')
      document.title = this.$t(this.$router.currentRoute.meta.title) + ' | ' + this.$t('GT banner text')
    },
    changeLocale () {
      if (this.linkLang === 'ENG') {
        this.$i18n.locale = 'en'
        window.localStorage.setItem('locale', 'en')
        this.$moment.locale('en')
      } else {
        this.$i18n.locale = 'ru'
        window.localStorage.setItem('locale', 'ru')
        this.$moment.locale('ru')
      }
    },
    openCorporateModal () {
      this.corporateModalActive = !this.corporateModalActive
    },
    GoToCorporate () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.corporateModalActive = false
          this.$store.commit('updateCorporate', true)
          this.$router.push('/order_cert')
          this.$store.commit('updateOrderStep', 'Certificates')
          window.localStorage.setItem('order', '')
          window.localStorage.setItem('OrderNumber', this.orderNumberC)
          window.localStorage.setItem('OrderPassword', this.orderPasswordC)
          window.localStorage.setItem('currency', '')
          window.localStorage.setItem('currencyId', '')
          window.localStorage.setItem('price', '')
          window.localStorage.setItem('course', '')
          window.localStorage.setItem('template', '')
          window.localStorage.setItem('templateId', '')
          window.localStorage.setItem('templateName', '')
          window.localStorage.setItem('templateData', '')
          window.localStorage.setItem('templateValidity', '')
        }
      })
    }
  },
  created () {
    const isValidPass = (value) => {
      return this.$http.get('/query/check-user/', { params: { order: this.orderNumberC, pass: value } }).then((response) => {
        return response ? response.data : null
      })
    }
    this.$validator.extend('checkPass', {
      validate: isValidPass
    })
  }
}
</script>

<style scoped lang="scss">
  .navbar-item img {
    max-height: 2.2rem;
  }
  .p_modal {
    color: #000000;
  }
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .locale-link {
    background-color: #2d865a;
  }
</style>
