<template>

  <footer class="footer footer-padding">
    <div class="container">
      <div style="height:0.5rem;"></div>
      <div class="content has-text-centered">
        <span class="has-text-white">© {{currentYear}} Gamma Technologies Research Laboratory LLP</span>
        <p class="has-text-white">
          <router-link to="/conditions" class="has-text-white">{{ $t('Terms of Use') }}</router-link> / <router-link to="/confedence" class="has-text-white">{{ $t('Privacy Policy') }}</router-link>
        </p>
        <div style="height:0.4rem;"></div>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      currentYear: (new Date()).getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
  .footer-padding {
    padding: 2rem 1rem 2rem 1rem;
  }
</style>
