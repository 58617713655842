import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    socket: null,
    socketKey: null,
    currency: null,
    currencyId: null,
    price: null,
    course: null,
    template: null,
    templateId: null,
    templateName: null,
    templateData: null,
    templateValidity: null,
    order: null,
    result: null,
    orderStep: null,
    instructionsStep: null,
    orderNumber: null,
    orderPassword: null,
    myTemplate: {},
    keyCheckOrder: null,
    checkOrgWithLegal: false,
    corporate: false,
    revokeData: null
  },
  mutations: {
    updateSocket (state, socket) {
      state.socket = socket
    },
    updateSocketKey (state, socketKey) {
      state.socketKey = socketKey
    },
    updateCurrency (state, currency) {
      state.currency = currency
    },
    updateCurrencyId (state, currencyId) {
      state.currencyId = currencyId
    },
    updatePrice (state, price) {
      state.price = price
    },
    updateCourse (state, course) {
      state.course = course
    },
    updateTemplate (state, template) {
      state.template = template
    },
    updateTemplateId (state, templateId) {
      state.templateId = templateId
    },
    updateTemplateName (state, templateName) {
      state.templateName = templateName
    },
    updateTemplateData (state, templateData) {
      state.templateData = templateData
    },
    updateTemplateValidity (state, templateValidity) {
      state.templateValidity = templateValidity
    },
    updateOrder (state, order) {
      state.order = order
    },
    updateResult (state, result) {
      state.result = result
    },
    updateOrderStep (state, orderStep) {
      state.orderStep = orderStep
    },
    updateInstructionsStep (state, instructionsStep) {
      state.instructionsStep = instructionsStep
    },
    updateOrderNumber (state, orderNumber) {
      state.orderNumber = orderNumber
    },
    updateOrderPassword (state, orderPassword) {
      state.orderPassword = orderPassword
    },
    updateMyTemplate (state, template) {
      state.myTemplate = template
    },
    updateKeyCheckOrder (state, order) {
      state.keyCheckOrder = order
    },
    updateCheckOrgWithLegal (state, value) {
      state.checkOrgWithLegal = value
    },
    updateCorporate (state, value) {
      state.corporate = value
    },
    updateRevokeData (state, value) {
      state.revokeData = value
    }
  },
  actions: {
    updateTemplate: ({ commit }, menu = null) => {
      commit('SET_ACTIVE_MENU', menu)
    },
    updateKeyCheckOrder: ({ commit }, order = null) => {
      commit('SET_KEY_CHECK_ORDER', order)
    },
    updateCheckOrgWithLegal: ({ commit }, value = false) => {
      commit('updateCheckOrgWithLegal', value)
    },
    updateCorporate: ({ commit }, value = false) => {
      commit('updateCorporate', value)
    }
  }
})
